import {
  Stat,
  StatLabel,
  StatHelpText,
  StatGroup,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Flex,
  Box,
  useColorModeValue,
  Divider,
} from "@chakra-ui/react";

const Admin = () => {
  return (
    <Flex
      minH={"100vh"}
      minW={"100vw"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Box
        rounded={"lg"}
        bg={useColorModeValue("white", "gray.700")}
        boxShadow={"lg"}
        p={8}
        minH={"100vh"}
        minW={"100vw"}
      >
        <StatGroup>
          <Stat>
            <StatLabel>Tasks Completed</StatLabel>
            <StatHelpText>180</StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>Words Written</StatLabel>
            <StatHelpText>10,000</StatHelpText>
          </Stat>
        </StatGroup>

        <Divider />
        <StatGroup>
          <Stat>
            <StatLabel>Translators</StatLabel>
            <StatHelpText>19</StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>Reviewers</StatLabel>
            <StatHelpText>7</StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>Admins</StatLabel>
            <StatHelpText>3</StatHelpText>
          </Stat>
        </StatGroup>
        <br />

        <TableContainer>
          <Table variant="striped" colorScheme="teal">
            <TableCaption>Translator Dashboard</TableCaption>
            <Thead>
              <Tr>
                <Th>ONGOING PROJECTS</Th>
                <Th>ONGOING TASKS</Th>
                <Th>NEXT TASKS</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                {/* change all td's to Link to and give linked access to the file */}
                <Td>Project 1</Td>
                <Td>Task</Td>
                <Td>Next Task 1</Td>
              </Tr>
              <Tr>
                <Td>Project 2</Td>
                <Td>Task 2</Td>
                <Td>Next Task 2</Td>
              </Tr>
            </Tbody>
            <Tr>
              <Td>Project 3</Td>
              <Td>Task 3</Td>
              <Td>Next Task 3</Td>
            </Tr>

            {/* <Tfoot>
              <Tr>
                <Th>To convert</Th>
                <Th>ONGOING TASKS</Th>
                <Th isNumeric>multiply by</Th>
              </Tr>
            </Tfoot> */}
          </Table>
        </TableContainer>
      </Box>
    </Flex>
  );
};
export default Admin;
