import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatGroup,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Button,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Flex,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, AddIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
const CreatedByYou = () => {
  return (
    <Flex
      minH={"100vh"}
      minW={"100vw"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Box
        rounded={"lg"}
        bg={useColorModeValue("white", "gray.700")}
        boxShadow={"lg"}
        p={8}
        minH={"100vh"}
        minW={"100vw"}
      >
        <Link to="/creation/project">
          <Button
            variant={"solid"}
            // colorScheme={""}
            size={"sm"}
            mr={4}
            leftIcon={<AddIcon />}
            p={4}
            color="white"
            fontWeight="bold"
            borderRadius="md"
            bgGradient="linear(to-r, teal.500, green.500)"
            _hover={{
              bgGradient: "linear(to-r, red.500, yellow.500)",
            }}
          >
            Create Project
          </Button>
        </Link>
        <br />
        <br />
        <br />

        <TableContainer>
          <Table variant="striped" colorScheme="teal">
            <TableCaption>Projects</TableCaption>
            <Thead>
              <Tr>
                <Th>PROJECTS</Th>
                <Th isNumeric>TASK COUNT</Th>
                <Th isNumeric>TASKS COMPLETED</Th>
                <Th isNumeric>WORDS WRITTEN (No.)</Th>
                <Th isNumeric>Translators (No.)</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Project 1</Td>
                <Td isNumeric>54</Td>
                <Td isNumeric>45</Td>
                <Td isNumeric>45</Td>
                <Td isNumeric>45</Td>
              </Tr>
              <Tr>
                <Td>Project 1</Td>
                <Td isNumeric>54</Td>
                <Td isNumeric>45</Td>
                <Td isNumeric>45</Td>
                <Td isNumeric>45</Td>
              </Tr>
            </Tbody>

            {/* <Tfoot>
              <Tr>
                <Th>To convert</Th>
                <Th>ONGOING TASKS</Th>
                <Th isNumeric>multiply by</Th>
              </Tr>
            </Tfoot> */}
          </Table>
        </TableContainer>
        <br />
        <br />
        <br />
        <TableContainer>
          <Table variant="striped" colorScheme="teal">
            <TableCaption>Tasks</TableCaption>
            <Thead>
              <Tr>
                <Th>PROJECTS</Th>
                <Th isNumeric>TASK COUNT</Th>

                <Th isNumeric>WORDS DONE (No.)</Th>
                <Th isNumeric>WORDS LEFT (No.)</Th>
                <Th isNumeric>TRANSLATORS (No.)</Th>

                <Th>STATUS</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Project 1</Td>
                <Td isNumeric>10</Td>
                <Td isNumeric>29</Td>
                <Td isNumeric>350</Td>
                <Td isNumeric>2</Td>
                <Td>Complete</Td>
              </Tr>
              <Tr>
                <Td>Project 2</Td>
                <Td isNumeric>13</Td>
                <Td isNumeric>09</Td>
                <Td isNumeric>150</Td>
                <Td isNumeric>1</Td>
                <Td>Incomplete</Td>
              </Tr>
            </Tbody>

            {/* <Tfoot>
              <Tr>
                <Th>To convert</Th>
                <Th>ONGOING TASKS</Th>
                <Th isNumeric>multiply by</Th>
              </Tr>
            </Tfoot> */}
          </Table>
        </TableContainer>
        <br />
        <br />
        <br />
        <TableContainer>
          <Table variant="striped" colorScheme="teal">
            <TableCaption>Subtasks</TableCaption>
            <Thead>
              <Tr>
                <Th>PROJECTS</Th>
                <Th isNumeric>TASK COUNT</Th>
                <Th isNumeric>SUBTASK COUNT</Th>
                <Th isNumeric>WORDS DONE (No.)</Th>
                <Th isNumeric>WORDS LEFT (No.)</Th>
                <Th isNumeric>TRANSLATORS (No.)</Th>

                <Th>STATUS</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Project 1</Td>
                <Td isNumeric>10</Td>
                <Td isNumeric>10</Td>
                <Td isNumeric>29</Td>
                <Td isNumeric>350</Td>
                <Td isNumeric>2</Td>
                <Td>Complete</Td>
              </Tr>
              <Tr>
                <Td>Project 2</Td>
                <Td isNumeric>13</Td>
                <Td isNumeric>09</Td>
                <Td isNumeric>09</Td>
                <Td isNumeric>150</Td>
                <Td isNumeric>1</Td>
                <Td>Incomplete</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Flex>
  );
};
export default CreatedByYou;
