import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatGroup,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Divider,
  TableContainer,
  Flex,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
const ManagerHome = () => {
  return (
    <Flex
      minH={"100vh"}
      // minW={"60vw"}
      // align={"center"}
      // justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Box
        rounded={"lg"}
        bg={useColorModeValue("white", "gray.700")}
        boxShadow={"lg"}
        p={8}
        minH={"100vh"}
        minW={"82vw"}
      >
        <StatGroup>
          <Stat>
            <StatLabel>Projects Participated</StatLabel>
            <StatNumber>10</StatNumber>
            <StatHelpText>Feb 12 - Dec 2</StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>Tasks Participated</StatLabel>
            <StatNumber>180</StatNumber>
            <StatHelpText>Feb 12 - Feb 28</StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>Subtasks Completed</StatLabel>
            <StatNumber>180</StatNumber>
            <StatHelpText>Feb 12 - Feb 28</StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>Words Written</StatLabel>
            <StatNumber>10,000</StatNumber>
            <StatHelpText>Feb 12 - Feb 28</StatHelpText>
          </Stat>
        </StatGroup>
        <br />
        <br />
        <br />

        {/* <TableContainer>
          <Table variant="striped" colorScheme="teal">
            <TableCaption>Next-Subtasks</TableCaption>
            <Thead>
              <Tr>
                <Th>TRANSLATION</Th>
                <Th>CAPTIONING</Th>
                <Th>TRANSLATION-REVIEW</Th>
                <Th>CAPTIONING-REVIEW</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                {/* change all td's to Link to and give linked access to the file */}
        {/* <Td>Project 1</Td>
                <Td>Task</Td>
                <Td>Next Task 1</Td>
                <Td>Task</Td>
              </Tr>
              <Tr>
                <Td>Project 2</Td>
                <Td>Task 2</Td>
                <Td>Next Task 2</Td>
                <Td>Task</Td>
              </Tr>
            </Tbody>
            <Tr>
              <Td>Project 3</Td>
              <Td>Task 3</Td>
              <Td>Next Task 3</Td>
              <Td>Task</Td>
            </Tr> */}

        {/* <Tfoot>
              <Tr>
                <Th>To convert</Th>
                <Th>ONGOING TASKS</Th>
                <Th isNumeric>multiply by</Th>
              </Tr>
            </Tfoot> */}
        {/* //   </Table>
        // </TableContainer> */}
        <Box
          border="1px solid"
          borderColor="gray.400"
          rounded="md"
          boxShadow="lg"
          overflow="hidden"
        >
          <Flex justify="center" p={5}>
            <chakra.h3 fontSize="xl" fontWeight="bold" textAlign="center">
              Dashboard
            </chakra.h3>
          </Flex>
          <Divider />
          <TableContainer>
            <Table variant="striped" colorScheme="teal">
              {/* <TableCaption>Subtasks</TableCaption> */}
              <Thead>
                <Tr>
                  <Th>PROJECTS</Th>
                  <Th>TASKS</Th>
                  <Th>SUBTASKS</Th>
                  <Th>TYPE OF WORK</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <Link to="/manager/projects">Project1</Link>
                  </Td>
                  <Td>
                    <Link to="/manager/tasks">Page 1-10 from the book</Link>
                  </Td>
                  <Td>
                    <Link to="/manager/subtasks">First two pages</Link>
                  </Td>
                  <Td>Translation</Td>
                </Tr>
                <Tr>
                  <Td>
                    <Link to="/manager/projects">Project 2</Link>
                  </Td>
                  <Td>
                    <Link to="/manager/tasks">Page 11-20 from the book</Link>
                  </Td>
                  <Td>
                    <Link to="/manager/subtasks">First two pages</Link>
                  </Td>
                  <Td>Captioning</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <br />
        <br />
      </Box>
    </Flex>
  );
};
export default ManagerHome;
