import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { UserAuth } from "../store/google-auth";
import { Link as Link1 } from "react-router-dom";
import img from "./LLTX-1.png";
const Links = [
  {
    name: "Home",
    navitem: "/",
  },
  {
    name: "Projects",
    navitem: "/manager/projects",
  },
  {
    name: "Tasks",
    navitem: "/manager/tasks",
  },
  {
    name: "Subtasks",
    navitem: "/manager/subtasks",
  },
];

const NavLink = ({ children }) => (
  <Link1 to={children.navitem}>
    <Box
      px={2}
      py={1}
      rounded={"md"}
      bg={useColorModeValue("gray.200", "gray.700")}
      _active={{
        textDecoration: "none",
        bg: useColorModeValue("gray.500", "gray.700"),
      }}
    >
      {children.name}
    </Box>
  </Link1>
);

export default function Navbar() {
  const navigate = useNavigate();
  const { user, logout } = UserAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleLogOut = async () => {
    try {
      await logout();
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={"center"}>
            <Box width="6%" marginTop="1%">
              <Link1 to="/">
                <img src={img} alt="" />
              </Link1>
            </Box>

            <HStack
              as={"nav"}
              spacing={3}
              display={{ base: "none", md: "flex" }}
            >
              {user &&
                Links.map((link) => <NavLink key={link.name}>{link}</NavLink>)}
            </HStack>
          </HStack>
          <Flex alignItems={"center"}>
            <Link1 to="/manager/text">
              <Button variant={"solid"} colorScheme={"teal"} size={"sm"} mr={4}>
                Text
              </Button>
            </Link1>
            <Link1 to="/manager/caption">
              <Button variant={"solid"} colorScheme={"teal"} size={"sm"} mr={4}>
                Caption
              </Button>
            </Link1>

            {user?.displayName || user ? (
              <>
                <Button
                  variant={"solid"}
                  colorScheme={"teal"}
                  size={"sm"}
                  mr={4}
                >
                  {user.displayName || user.email}
                </Button>
                <Button
                  variant={"solid"}
                  colorScheme={"teal"}
                  size={"sm"}
                  mr={4}
                  onClick={handleLogOut}
                >
                  Log Out
                </Button>
              </>
            ) : (
              <></>
            )}

            <Menu>
              <MenuButton
                as={Button}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
              ></MenuButton>
              <MenuList>
                <MenuItem>Link 1</MenuItem>
                <MenuItem>Link 2</MenuItem>
                <MenuDivider />
                <MenuItem>Link 3</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {Links.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
