import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorModeValue,
  Box,
  chakra,
  Flex,
  Divider,
  TableContainer,
} from "@chakra-ui/react";

const networks = [
  {
    name: "Ram",
    visotors: "Shyam",
    visotorsRatio: "70%",
  },
  {
    name: "Shyam",
    visotors: "Shiva",
    visotorsRatio: "50%",
  },
  {
    name: "Shiva",
    visotors: "Ram",
    visotorsRatio: "40%",
  },
];
import { AddIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
const Subtasks = () => {
  return (
    <Flex
      minH={"100vh"}
      minW={"100vw"}
      //   align={"center"}
      //   justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Box
        rounded={"lg"}
        bg={useColorModeValue("white", "gray.700")}
        boxShadow={"lg"}
        p={8}
        minH={"100vh"}
        minW={"80vw"}
      >
        <Link to="/creation/subtasks">
          <Button
            variant={"solid"}
            // colorScheme={""}
            size={"sm"}
            mr={4}
            leftIcon={<AddIcon />}
            p={4}
            color="white"
            fontWeight="bold"
            borderRadius="md"
            bgGradient="linear(to-r, teal.500, green.500)"
            _hover={{
              bgGradient: "linear(to-r, red.500, yellow.500)",
            }}
          >
            Create Subtask
          </Button>
        </Link>
        <br />
        <br />
        <br />

        <Box
          border="1px solid"
          borderColor="gray.400"
          rounded="md"
          boxShadow="lg"
          overflow="hidden"
        >
          <Flex justify="center" p={5}>
            <chakra.h3 fontSize="xl" fontWeight="bold" textAlign="center">
              Subtasks
            </chakra.h3>
          </Flex>
          <Divider />
          <TableContainer>
            <Table variant="striped" colorScheme="teal">
              {/* <TableCaption>Subtasks</TableCaption> */}
              <Thead>
                <Tr>
                  <Th>PROJECTS</Th>
                  <Th>CREATED BY</Th>
                  <Th isNumeric>TASK COUNT</Th>
                  <Th isNumeric>SUBTASK COUNT</Th>
                  <Th isNumeric>WORDS DONE (No.)</Th>
                  <Th isNumeric>WORDS LEFT (No.)</Th>
                  <Th isNumeric>TRANSLATORS (No.)</Th>

                  <Th>STATUS</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Project 1</Td>
                  <Td>Saurabh Surana</Td>
                  <Td isNumeric>10</Td>
                  <Td isNumeric>10</Td>
                  <Td isNumeric>29</Td>
                  <Td isNumeric>350</Td>
                  <Td isNumeric>2</Td>
                  <Td>Complete</Td>
                </Tr>
                <Tr>
                  <Td>Project 2</Td>
                  <Td>Saurabh Surana</Td>
                  <Td isNumeric>13</Td>
                  <Td isNumeric>09</Td>
                  <Td isNumeric>09</Td>
                  <Td isNumeric>150</Td>
                  <Td isNumeric>1</Td>
                  <Td>Incomplete</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <br />
        <br />
      </Box>
    </Flex>
  );
};
export default Subtasks;
