import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatGroup,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  List,
  ListIcon,
  ListItem,
  ListItemProps,
  ListProps,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Container,
  Box,
  chakra,
  Flex,
  Divider,
  TableContainer,
} from "@chakra-ui/react";

const networks = [
  {
    name: "Instagram",
    visotors: "3,550",
    visotorsRatio: "70%",
  },
  {
    name: "Twitter",
    visotors: "1,229",
    visotorsRatio: "50%",
  },
  {
    name: "Facebook",
    visotors: "1,115",
    visotorsRatio: "40%",
  },
];

const Assigned = () => {
  return (
    <Flex
      minH={"100vh"}
      // minW={"60vw"}
      // align={"center"}
      // justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Box
        rounded={"lg"}
        bg={useColorModeValue("white", "gray.700")}
        boxShadow={"lg"}
        p={8}
        minH={"100vh"}
        minW={"82vw"}
      >
        <Container maxW="100vw" py={10} px={4}>
          <Box
            border="1px solid"
            borderColor="gray.400"
            rounded="md"
            boxShadow="lg"
            overflow="hidden"
          >
            <Flex justify="center">
              <chakra.h3 fontSize="xl" fontWeight="bold" textAlign="center">
                Projects
              </chakra.h3>
            </Flex>
            <Divider />
            <TableContainer>
              <Table size="md">
                <Thead>
                  <Tr fontWeight="900">
                    <Th>Network</Th>
                    <Th>Visitors</Th>
                    {/* <Th>New Users Rate</Th> */}
                  </Tr>
                </Thead>
                <Tbody>
                  {networks.map((network, index) => (
                    <Tr key={index}>
                      <Td fontSize="sm">{network.name}</Td>
                      <Td fontSize="sm">{network.visotors}</Td>
                      <Td>
                        <Box
                          w="100%"
                          bg={useColorModeValue("gray.300", "gray.600")}
                          rounded="md"
                        >
                          <Box
                            w={network.visotorsRatio}
                            h={2}
                            bg="blue.400"
                            rounded="md"
                          ></Box>
                        </Box>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            border="1px solid"
            borderColor="gray.400"
            rounded="md"
            boxShadow="lg"
            overflow="hidden"
          >
            <Flex justify="center" p={5}>
              <chakra.h3 fontSize="xl" fontWeight="bold" textAlign="center">
                Tasks
              </chakra.h3>
            </Flex>
            <Divider />
            <TableContainer>
              <Table size="md">
                <Thead>
                  <Tr fontWeight="900">
                    <Th>Network</Th>
                    <Th>Visitors</Th>
                    <Th>New Users Rate</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {networks.map((network, index) => (
                    <Tr key={index}>
                      <Td fontSize="sm">{network.name}</Td>
                      <Td fontSize="sm">{network.visotors}</Td>
                      <Td>
                        <Box
                          w="100%"
                          bg={useColorModeValue("gray.300", "gray.600")}
                          rounded="md"
                        >
                          <Box
                            w={network.visotorsRatio}
                            h={2}
                            bg="blue.400"
                            rounded="md"
                          ></Box>
                        </Box>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            border="1px solid"
            borderColor="gray.400"
            rounded="md"
            boxShadow="lg"
            overflow="hidden"
          >
            <Flex justify="center" p={5}>
              <chakra.h3 fontSize="xl" fontWeight="bold" textAlign="center">
                Subtasks
              </chakra.h3>
            </Flex>
            <Divider />
            <TableContainer>
              <Table size="md">
                <Thead>
                  <Tr fontWeight="900">
                    <Th>Network</Th>
                    <Th>Visitors</Th>
                    <Th>New Users Rate</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {networks.map((network, index) => (
                    <Tr key={index}>
                      <Td fontSize="sm">{network.name}</Td>
                      <Td fontSize="sm">{network.visotors}</Td>
                      <Td>
                        <Box
                          w="100%"
                          bg={useColorModeValue("gray.300", "gray.600")}
                          rounded="md"
                        >
                          <Box
                            w={network.visotorsRatio}
                            h={2}
                            bg="blue.400"
                            rounded="md"
                          ></Box>
                        </Box>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      </Box>
    </Flex>
  );
};
export default Assigned;
// const networks = [
//   {
//     name: 'Instagram',
//     visotors: '3,550',
//     visotorsRatio: '70%'
//   },
//   {
//     name: 'Twitter',
//     visotors: '1,229',
//     visotorsRatio: '50%'
//   },
//   {
//     name: 'Facebook',
//     visotors: '1,115',
//     visotorsRatio: '40%'
//   }
// ];

// // const MediaTraffic = () => {
// //   return (
// //     <Container maxW="3xl" py={10} px={4}>
// //       <Box border="1px solid" borderColor="gray.400" rounded="md" boxShadow="lg" overflow="hidden">
// //         <Flex justify="left" p={5}>
// //           <chakra.h3 fontSize="xl" fontWeight="bold" textAlign="center">
// //             Social Media Traffic
// //           </chakra.h3>
// //         </Flex>
// //         <Divider />
// //         <TableContainer>
// //           <Table size="md">
// //             <Thead>
// //               <Tr fontWeight="900">
// //                 <Th>Network</Th>
// //                 <Th>Visitors</Th>
// //                 <Th>New Users Rate</Th>
// //               </Tr>
// //             </Thead>
// //             <Tbody>
// //               {networks.map((network, index) => (
// //                 <Tr key={index}>
// //                   <Td fontSize="sm">{network.name}</Td>
// //                   <Td fontSize="sm">{network.visotors}</Td>
// //                   <Td>
// //                     <Box w="100%" bg={useColorModeValue('gray.300', 'gray.600')} rounded="md">
// //                       <Box w={network.visotorsRatio} h={2} bg="blue.400" rounded="md"></Box>
// //                     </Box>
// //                   </Td>
// //                 </Tr>
// //               ))}
// //             </Tbody>
// //           </Table>
// //         </TableContainer>
// //       </Box>
// //     </Container>
// //   );
// // };

// // export default MediaTraffic;
