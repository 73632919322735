import React from "react";
import Signin from "./Signin";
import Signup from "./Signup";
import { Flex } from "@chakra-ui/react";
import "./Home.css";
function Home() {
  return (
    <div className="home">
      <Signin />
    </div>
  );
}

export default Home;
