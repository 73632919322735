import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorModeValue,
  TableCaption,
  Box,
  chakra,
  Flex,
  Divider,
  TableContainer,
} from "@chakra-ui/react";

import { AddIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
const Projects = () => {
  return (
    <Flex
      minH={"100vh"}
      minW={"100vw"}
      //   align={"center"}
      //   justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Box
        rounded={"lg"}
        bg={useColorModeValue("white", "gray.700")}
        boxShadow={"lg"}
        p={8}
        minH={"100vh"}
        minW={"80vw"}
      >
        <Link to="/creation/projects">
          <Button
            variant={"solid"}
            // colorScheme={""}
            size={"sm"}
            mr={4}
            leftIcon={<AddIcon />}
            p={4}
            color="white"
            fontWeight="bold"
            borderRadius="md"
            bgGradient="linear(to-r, teal.500, green.500)"
            _hover={{
              bgGradient: "linear(to-r, red.500, yellow.500)",
            }}
          >
            Create Projects
          </Button>
        </Link>
        <br />
        <br />
        <br />

        {/* <Box
          border="1px solid"
          borderColor="gray.400"
          rounded="md"
          boxShadow="lg"
          overflow="hidden"
        >
          <Flex justify="center" p={5}>
            <chakra.h3 fontSize="xl" fontWeight="bold" textAlign="center">
              Projects
            </chakra.h3>
          </Flex>
          <Divider />
          <TableContainer>
            <Table size="md">
              <Thead>
                <Tr fontWeight="900">
                  <Th>Title</Th>
                  <Th>Created By</Th>
                  <Th>Completed %</Th>
                </Tr>
              </Thead>
              <Tbody>
                {networks.map((network, index) => (
                  <Tr key={index}>
                    <Td fontSize="sm">
                      <Link to="/manager/tasks">{network.name}</Link>
                    </Td>
                    <Td fontSize="sm">{network.visotors}</Td>
                    <Td>
                      <Box
                        w="100%"
                        bg={useColorModeValue("gray.300", "gray.600")}
                        rounded="md"
                      >
                        <Box
                          w={network.visotorsRatio}
                          h={2}
                          bg="blue.400"
                          rounded="md"
                        ></Box>
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <br />
        <br /> */}
        <Box
          border="1px solid"
          borderColor="gray.400"
          rounded="md"
          boxShadow="lg"
          overflow="hidden"
        >
          <TableContainer>
            <Flex justify="center" p={5}>
              <chakra.h3 fontSize="xl" fontWeight="bold" textAlign="center">
                Projects
              </chakra.h3>
            </Flex>
            <Table variant="striped" colorScheme="teal">
              {/* <TableCaption>Projects</TableCaption> */}
              <Thead>
                <Tr>
                  <Th>PROJECTS</Th>
                  <Th>CREATED BY</Th>
                  <Th isNumeric>TASK COUNT</Th>
                  <Th isNumeric>TASKS COMPLETED</Th>
                  <Th isNumeric>WORDS WRITTEN (No.)</Th>
                  <Th isNumeric>Translators (No.)</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <Link to="/manager/tasks">Project 1</Link>
                  </Td>
                  <Td>Sahaj Kedia</Td>
                  <Td isNumeric>54</Td>
                  <Td isNumeric>45</Td>
                  <Td isNumeric>45</Td>
                  <Td isNumeric>45</Td>
                </Tr>
                <Tr>
                  <Td>Project 1</Td>
                  <Td>Sahaj Kedia</Td>
                  <Td isNumeric>54</Td>
                  <Td isNumeric>45</Td>
                  <Td isNumeric>45</Td>
                  <Td isNumeric>45</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Flex>
  );
};
export default Projects;
