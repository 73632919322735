import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Center,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signin } from "../../tools/auth-tools";
import { useRef } from "react";
import { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { UserAuth } from "../../store/google-auth";
import { Link as RouteLink } from "react-router-dom";
export default function Signin() {
  const { googleSignIn, user } = UserAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  let navigate = useNavigate();
  const { signin } = UserAuth();
  const submitHandler = async () => {
    //  (email === "" || password === "") ? console.log("TRY AGAIN"):

    // else
    //   signin({ email, password }).then((data) => {
    //     if (!data) navigate("/signup");
    //   });

    try {
      signin(email, password);
      console.log(email, password);
    } catch (err) {
      console.log(err.code);
      if (err.code === "400") navigate("/signup");
      console.log(err, "HI");
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
      //IF THE EMAIL USED TO SIGN IN IS NOT PRESENT IN THE DATABASE THEN REDIRECT TO SIGN UP ELSE REDIRECT TO TRANSLATOR DASH
      navigate("/manager");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (user != null) navigate("/manager");
  }, [user]);
  return (
    <Flex
      minH={"100vh"}
      minW={"100vw"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Sign in to your account</Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            to enjoy all of our cool <Link color={"blue.400"}>features</Link> ✌️
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input type="email" onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Checkbox>Remember me</Checkbox>
                <Link color={"blue.400"}>Forgot password?</Link>
              </Stack>
              <Button
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
                onClick={submitHandler}
              >
                Sign in
              </Button>
              {/* <br /> */}
              <RouteLink to="/signup">
                <Link color={"blue.400"}>Signup instead!!!</Link>
              </RouteLink>

              <Center p={8}>
                <Button
                  w={"full"}
                  maxW={"md"}
                  variant={"outline"}
                  leftIcon={<FcGoogle />}
                  onClick={handleGoogleSignIn}
                >
                  <Center>
                    <Text>Sign in with Google</Text>
                  </Center>
                </Button>
              </Center>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
