import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
export default function Task() {
  const [red, setRed] = useState(false);
  const [red1, setRed1] = useState(false);
  const [src, setSrc] = useState();
  const [dest, setDest] = useState();
  const handleChangeSrc = (e) => {
    setSrc(e.target.value);
    if (e.target.value == "others") setRed(true);
    // console.log(src);
  };
  const handleChangeDest = (e) => {
    setDest(e.target.value);
    if (e.target.value == "others") setRed1(true);
    // console.log(dest);
  };
  return (
    <Flex
      minH={"60vh"}
      minW={"60vw"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"80%"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"} textAlign={"center"}>
            Task Creation
          </Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            create a Task and assign it to someone
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <Box>
              <FormControl id="Name" isRequired>
                <FormLabel>Name of the task</FormLabel>
                <Input type="text" />
              </FormControl>
            </Box>
            <FormControl id="source-type" isRequired>
              <FormLabel>Source Type</FormLabel>
              <Select placeholder="select">
                <option value="option1">Wikipedia</option>
                <option value="option2">Book </option>
                <option value="option3">URL </option>
                <option value="option4">Others </option>
              </Select>
            </FormControl>
            <FormControl id="source" isRequired>
              <FormLabel>Source</FormLabel>
              <Input type="text" placeholder="a url or a book's name" />
            </FormControl>
            <FormControl id="source-language" isRequired>
              <FormLabel>Source Language</FormLabel>
              <Select
                placeholder="select"
                value={src}
                onChange={handleChangeSrc}
              >
                <option value="en">English</option>
                <option value="hi">Hindi </option>
                <option value="or">Oriya </option>
                <option value="others">Others </option>
              </Select>
            </FormControl>
            {red ? (
              <FormControl id="source-language" isRequired>
                <FormLabel>ISO 639-1 CODE of Source Language</FormLabel>

                <Input type="text" placeholder="ISO 639-1 code" />
              </FormControl>
            ) : (
              <></>
            )}
            <FormControl id="destination-language" isRequired>
              <FormLabel>Destination Language</FormLabel>

              <Select
                placeholder="select"
                value={dest}
                onChange={handleChangeDest}
              >
                <option value="en">English</option>
                <option value="hi">Hindi </option>
                <option value="or">Oriya </option>
                <option value="others">Others </option>
              </Select>
            </FormControl>
            {red1 && (
              <FormControl id="destination-language" isRequired>
                <FormLabel>ISO 639-1 CODE of Destination Language</FormLabel>

                <Input type="text" placeholder="ISO 639-1 code" />
              </FormControl>
            )}
            <Stack spacing={10} pt={2}>
              <Button
                loadingText="Submitting"
                size="lg"
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
              >
                Create
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
