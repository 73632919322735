import { Routes, Route } from "react-router-dom";
import Admin from "./pages/Dashboard/Admin";
import ManagerHome from "./pages/Manager/Home";
import Home from "./pages/Homepage/Home";
import Signup from "./pages/Homepage/Signup";
import Protected from "./components/Protected";
import SimpleSidebar from "./components/Sidebar";
import Assigned from "./pages/Manager/Assigned";
import CreatedByYou from "./pages/Manager/CreatedByYou";
import Project from "./pages/Creation/Project";
import Task from "./pages/Creation/Task";
import Subtask from "./pages/Creation/Subtask";
import Projects from "./pages/Manager/Projects";
import Subtasks from "./pages/Manager/Subtasks";
import Tasks from "./pages/Manager/Tasks";
import Transliteration from "./pages/Manager/Transliteration";
import Captioning from "./pages/Manager/Captioning";
export default function Routing() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="signup" element={<Signup />} />
      <Route
        path="/manager"
        element={
          <Protected>
            <SimpleSidebar>
              <ManagerHome />{" "}
            </SimpleSidebar>
          </Protected>
        }
      />
      <Route
        path="/manager/assigned"
        element={
          <Protected>
            <SimpleSidebar>
              <Assigned />{" "}
            </SimpleSidebar>
          </Protected>
        }
      />
      <Route
        path="/manager/createdbyyou"
        element={
          <Protected>
            <SimpleSidebar>
              <CreatedByYou />{" "}
            </SimpleSidebar>
          </Protected>
        }
      />
      <Route
        path="admindash"
        element={
          <Protected>
            <Admin />
          </Protected>
        }
      />

      <Route
        path="/manager/projects"
        element={
          <Protected>
            <SimpleSidebar>
              <Projects />{" "}
            </SimpleSidebar>
          </Protected>
        }
      />

      <Route
        path="/manager/tasks"
        element={
          <Protected>
            <SimpleSidebar>
              <Tasks />{" "}
            </SimpleSidebar>
          </Protected>
        }
      />

      <Route
        path="/manager/subtasks"
        element={
          <Protected>
            <SimpleSidebar>
              <Subtasks />{" "}
            </SimpleSidebar>
          </Protected>
        }
      />

      {/* Creation Pages */}

      <Route
        path="/creation/projects"
        element={
          <Protected>
            <Project />{" "}
          </Protected>
        }
      />
      <Route
        path="/creation/tasks"
        element={
          <Protected>
            <Task />{" "}
          </Protected>
        }
      />
      <Route
        path="/creation/subtasks"
        element={
          <Protected>
            <Subtask />{" "}
          </Protected>
        }
      />
      <Route
        path="/manager/text"
        element={
          <Protected>
            <Transliteration />{" "}
          </Protected>
        }
      />
      <Route
        path="/manager/caption"
        element={
          <Protected>
            <Captioning />
          </Protected>
        }
      />
    </Routes>
  );
}
