import "./styles.css";
require("dotenv").config();
import Routing from "./Routing";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./store/google-auth";
import Navbar from "./components/Navbar";
export default function App() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <Navbar />
        <Routing />
      </AuthContextProvider>
    </BrowserRouter>
  );
}
