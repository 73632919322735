import { initializeApp } from "firebase/app";
// import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
import { getAuth } from "firebase/auth";
require("dotenv").config();
// console.log(process.env.REACT_APP_API_KEY);
const firebaseConfig = {
  apiKey:
    process.env.REACT_APP_API_KEY || "AIzaSyAzu6-SO0Abd5rcqk-vtZNNcQUiY9cGFsk",
  authDomain:
    process.env.REACT_APP_AUTH_DOMAIN ||
    "translator-manager-880b6.firebaseapp.com",
  projectId: process.env.REACT_APP_PROJECT_ID || "translator-manager-880b6",
  storageBucket:
    process.env.REACT_APP_STORAGE_BUCKET ||
    "translator-manager-880b6.appspot.com",
  messagingSenderId:
    process.env.REACT_APP_MESSAGING_SENDER_ID || "503637723668",
  appId:
    process.env.REACT_APP_APPID || "1:503637723668:web:a069259511d4f2b6a2b501",
  measurementId: process.env.REACT_APP_MEASUREMENTID || "G-CYQ7LKDF65",
};
const app = initializeApp(firebaseConfig);
export const auth = getAuth();

export default app;
